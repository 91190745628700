import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav } from "react-bootstrap";
import './assets/App.css';

function Navegacao (props) {
    return (
        <Navbar collapseOnSelect expand="lg" variant="light">
            <Navbar.Brand href="/" aria-label="Logotipo Curso de Sushi - Home Page" roles="button">
                <div className="logo">Curso De Sushi</div>
                <div className="slogan">Online</div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                <Nav.Link href="#modulos" aria-label="Ir para a sessão Módulos">Módulos</Nav.Link>
                <Nav.Link href="#caracteristicas" aria-label="Ir para a sessão Características do Curso">Características do Curso</Nav.Link>
                <Nav.Link href="#preco" aria-label="Ir para a sessão Preço">Preço</Nav.Link>
                {/* <Nav.Link href="#">Receber e-mail de promoções</Nav.Link> */}
                </Nav>
                <Nav>
                <Nav.Link eventKey={2} href={props.linkDoCurso} aria-label="Ir para a sessão Iniciar aulas">
                    Iniciar aulas
                </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}



export default Navegacao;